<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="(event) => updateSidebarVisible(event)"
  >
    <CSidebarBrand>
      <img
        class="d-block"
        src="logo_main.png"
        style="width: 220px"
        alt="slide 1"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="50"
      />
    </CSidebarBrand>
    <AppSidebarNav :t="$t" />
    <CSidebarFooter>
      <CRow>
        <CCol></CCol>
      </CRow>
      <CSidebarNav class="flex-column">
        <CNavItem style="padding-top: 10px">
          <CButton color="light" variant="ghost" @click.prevent="logoutUserFun">
            <CIcon :icon="cilUser" size="xl" />
            {{ UserName }}
          </CButton>
        </CNavItem>
        <CNavItem style="padding-top: 8px">
          <CButton
            color="danger"
            variant="ghost"
            @click.prevent="logoutUserFun"
          >
             {{$t('logout.title')}}
          </CButton>
        </CNavItem>

        <CNavItem style="padding-top: 8px" v-if="false">
          <el-select v-model="locale" @change="changeSelect">
            <el-option label="繁體中文" value="tw"></el-option>
            <el-option label="English" value="en"></el-option>
          </el-select>
        </CNavItem>
      </CSidebarNav>
      <CRow>
        <CCol></CCol>
      </CRow>
    </CSidebarFooter>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { cilUser } from '@coreui/icons'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { useLogin } from '@/modules/login/useLogin'
import { useI18n } from "vue-i18n";

export default {
  name: 'AppSidebar',
  // inject: ['reload'],
  components: {
    AppSidebarNav,
  },
  setup() {
    const { locale } = useI18n()

    const store = useStore()
    const router = useRouter()
    const UserName = ref('')
    //: store.state.user.Name
    const { verifyToken, updateSideBarVisiable, logoutUser, token } = useLogin()
    const logoutUserFun = () => {
      logoutUser()
      router.push('/login')
    }

    onMounted(async () => {
      const logoutUser = () => {
        store.dispatch('logoutUser')
        router.push('/login')
      }

      if (token) {
        //驗證登入狀態
        const response = await verifyToken()
        // console.log('verify:' + JSON.stringify(response))
        if (response.success) {
          //console.log('success:' + response.data.success)
          // 寫入 JWT
          // store.dispatch('loginUser', response.data.value.User)
          // store.dispatch('loginToken', response.data.value.Token)
          UserName.value = response.value.User.Name
          //console.log('state:' + JSON.stringify(store.state))
        } else {
          logoutUser()
        }
      } else {
        logoutUser()
      }
      //console.log('state:' + JSON.stringify(store.state))
    })

    const updateSidebarVisible = (event) => {
      // console.log('updateSidebarVisible', event)
      updateSideBarVisiable(event)
    }

    const changeSelect = async (val) => {
      localStorage.setItem("locale", val);
      locale.value= val
    }

    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      cilUser,
      UserName,
      logoutUserFun,
      updateSidebarVisible,
      changeSelect,
      locale,
    }
  },
  methods: {},
}
</script>
