export const SET_GATEWAY = 'setGateway'
export const SET_GATEWAY_ALL = 'setGatewayAll'

export const SET_GATEWAY_TOTAL = 'setGatewayTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_GATEWAY](state, gateway) {
    state.gateway = gateway
  },
  [SET_GATEWAY_ALL](state, gateway_all) {
    state.gateway_all = gateway_all
  },
  [SET_GATEWAY_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
