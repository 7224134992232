import { SET_LAMP, SET_LAMP_TOTAL, SET_LAMPS, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchLamp({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/lamp/list',
      { params: params },
    )
    commit(SET_LAMPS, response?.data.value ?? [])
    commit(SET_LAMP_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchLampItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/lamp/get',
      { params: params },
    )
    commit(SET_LAMP, response?.data.value ?? [])
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createLamp({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/lamp',
      params,
    )
    commit(SET_LAMP, response?.data.value ?? [])
    commit(SET_LAMP_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
  },
}
