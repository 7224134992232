import { useStore } from 'vuex'
import { computed } from 'vue'

export function useLogin() {
  const store = useStore()
  const login = async (params) => {
    // console.log('params', params)
    return await store.dispatch('login/doLogin', params)
  }
  const verifyToken = async (params) => {
    return await store.dispatch('login/verifyToken', params)
  }
  const loginUser = async (params) => {
    await store.dispatch('login/loginUser', params)
  }

  const loginToken = async (params) => {
    return await store.dispatch('login/loginToken', params)
  }

  const logoutUser = async (params) => {
    await store.dispatch('login/logoutUser', params)
  }

  const updateSideBarVisiable = async (params) => {
    await store.dispatch('login/updateSideBarVisiable', params)
  }
  // eslint-disable-next-line vue/return-in-computed-property
  const token = () => {
    return localStorage.getItem('token')
  }
  const permission = () => {
    console.log('get permission ', localStorage.getItem('Permission'))
    return localStorage.getItem('Permission')
  }
  const customerNo = () => {
    return localStorage.getItem('CustomerNo')
  }
  return {
    login,
    verifyToken,
    loginUser,
    loginToken,
    logoutUser,
    updateSideBarVisiable,
    permission,
    customerNo,
    lang: computed(() => {
      return store.state.login.lang
    }),
    sidebarVisible: computed(() => {
      return store.state.login.sidebarVisible
    }),
    sidebarUnfoldable: computed(() => {
      return store.state.login.sidebarUnfoldable
    }),
    isLoggedIn: computed(() => {
      return store.state.login.isLoggedIn
    }),
    isSystem: computed(() => {
      return store.state.login.isSystem
    }),
    token,
    user: computed(() => {
      return store.state.login.user
    }),
    loading: computed(() => {
      return store.state.login.loading
    }),
  }
}
