export const SET_SIGNINOUT = 'setSigninout'
export const SET_SIGNINOUT_TOTAL = 'setSigninoutTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_SIGNINOUT](state, signinout) {
    state.signinout = signinout
  },
  [SET_SIGNINOUT_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
