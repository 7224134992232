import { SET_LOGS, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchLogs({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/log_malfunction/list',
      { params: params },
    )
    commit(SET_LOGS, response?.data?.value ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
