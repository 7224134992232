import {SET_BRIDGE_TOTAL,SET_BRIDGE, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchBridges({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge/list',
      { params: params },
    )
    commit(SET_BRIDGE, response?.data.value ?? [])
    commit(SET_BRIDGE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge/get',
      { params: params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/add',
      { ...params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/edit',
      { ...params },
    )
    return response?.data.value || []
  },
  async deleteBridge({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge/delete',
      { ...params },
    )
    return response?.data.value || []
  },
}
