import { SET_LOADING, SET_FREQUENCY, SET_CHART } from './mutations'
import axios from 'axios'

export default {
  async fetchFrequency({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/frequency',
      { params: params },
    )

    // console.log('response freq', response.data.value)
    // console.log('params', params.label('dashboard.nbiot.UnderVoltage'))

    const chart = {
      labels: ['功率異常', '過電壓', '無回應(超過24小時)', '低電壓'],
      datasets: [
        {
          backgroundColor: [
            'white',
            'yellow',
            'orange',
            'pink',
            'black',
            '#666',
            'purple',
            '#333',
            'red',
            'green',
            'blue',
            '#00FFFF',
            '#006400',
          ],
          data: [40, 20, 80, 10],
        },
      ],
    }

    chart.labels = response.data?.value?.labels?.map((item) => {
      return params.label('dashboard.nbiot.'+item)
    })

    chart.datasets[0].data = response.data?.value?.datas?.map((item) => {
      return item
    })
    commit(SET_CHART, chart ?? [])

    commit(SET_FREQUENCY, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
