import {SET_BRIDGE_SCHEDULE,SET_BRIDGE_SCHEDULE_TOTAL, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchBridgeSchedules({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/list',
      { params: params },
    )
    commit(SET_BRIDGE_SCHEDULE, response?.data.value ?? [])
    commit(SET_BRIDGE_SCHEDULE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchBridgeSchedule({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/item',
      { params: params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchBridgeScheduleItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/itemlist',
      { params: params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createBridgeSchedule({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/add',
      { ...params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateBridgeSchedule({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/edit',
      { ...params },
    )
    return response?.data.value || []
  },
  async deleteBridgeSchedule({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/del',
      { ...params },
    )
    return response?.data.value || []
  },
  async deleteBridgeScheduleItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/delitem',
      { ...params },
    )
    return response?.data.value || []
  },
  async addBridgeScheduleItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/bridge_schedule/additems',
      { ...params },
    )
    return response?.data.value || []
  },
}
