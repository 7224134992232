export const SET_PARAM_QUE = 'setParamQue'
export const SET_PARAM_QUE_TOTAL = 'setParamQueTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_PARAM_QUE](state, paramQue) {
    state.paramQue = paramQue
  },
  [SET_PARAM_QUE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
