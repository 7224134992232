export const SET_LOG_LOSTS = 'setLogLosts'
export const SET_LOG_LOSTS_TOTAL = 'setLogLostsTotal'
export const SET_LOADING = 'setLoading'

export default {
  [SET_LOG_LOSTS](state, logLosts) {
    state.logLosts = logLosts
  },
  [SET_LOG_LOSTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
}
