export default {
  lang: 'tw',
  sidebarVisible: '',
  sidebarUnfoldable: false,
  isLoggedIn: false,
  isSystem: false,
  token: null,
  user: null,
  loading: false, // 初始化 loading 狀態為 false
  permission: [],
  chart: {},
  customerNo: {},
}
