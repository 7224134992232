export const SET_LOGNOTIFY = 'setLognotify'
export const SET_LOGNOTIFY_TOTAL = 'setLognotifyTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_LOGNOTIFY](state, lognotify) {
    state.lognotify = lognotify
  },
  [SET_LOGNOTIFY_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
