export const SET_GROUP_SET = 'setGroupSet'
export const SET_GROUP_SET_TOTAL = 'setGroupSetTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_GROUP_SET](state, groupSet) {
    state.groupSet = groupSet
  },
  [SET_GROUP_SET_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
