export default {
  loading: false,
  searchType: 1,
  quality: {
    success: true,
    message: '操作成功',
    errorcode: 0,
    value: {
      labels: [],
      datas: [],
      WattByDat: 0,
      WattByMonth: 640,
      WattByYear: 640,
    },
  },
  chart: {
    labels: [],
    datasets: [
      {
        label: '',
        backgroundColor: 'rgb(0,139,0,1)',
        data: [],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
