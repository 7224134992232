export const SET_CONTROLLERS = 'setControllers'
export const SET_CONTROLLER_TOTAL = 'setControllerTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_CONTROLLERS](state, controller) {
    state.controller = controller
  },
  [SET_CONTROLLER_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
