import {
  SET_LOST_CONNECT_FREQ,
  SET_LOST_CONNECT_FREQ_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchLostConnectFrequency({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/lostConnectionFrequency/list',
      { params: params },
    )
    commit(SET_LOST_CONNECT_FREQ, response?.data.value ?? [])
    commit(SET_LOST_CONNECT_FREQ_TOTAL, response?.data?.total_count ?? 0)

    commit(SET_LOADING, false)
    return response
  },
}
