export const SET_CONTROLLER_DEF = 'setControllerDef'
export const SET_CONTROLLER_DEF_TOTAL = 'setControllerDefTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_CONTROLLER_DEF](state, controllerDef) {
    state.controllerDef = controllerDef
  },
  [SET_CONTROLLER_DEF_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
