import { SET_LOADING, SET_USER, SET_USER_TOTAL, SET_PAGE } from './mutations'
import axios from 'axios'

export default {
  async fetchUsers({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/user/list',
      { params: params },
    )
    commit(SET_USER, response?.data.value ?? [])
    commit(SET_USER_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
}
