export const SET_LANG = 'setLang'
export const SET_TOGGLE_SIDEBAR = 'setToggleSidebar'
export const SET_TOGGLE_UNFOLDABLE = 'setToggleUnfoldable'
export const SET_UPDATE_SIDEBAR_VISIBLE = 'updateSidebarVisible'
export const SET_TOKEN = 'setToken'
export const SET_CUSTOMER_NO = 'setCustomerNo'
export const SET_USER = 'setUser'
export const CLEAR_TOKEN = 'clearToken'
export const SET_PERMISSION = 'setPermission'

export default {
  [SET_LANG](state, value) {
    state.lang = value
    localStorage.setItem('lang', value)
  },
  [SET_TOGGLE_SIDEBAR](state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  [SET_TOGGLE_UNFOLDABLE](state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  [SET_UPDATE_SIDEBAR_VISIBLE](state, payload) {
    state.sidebarVisible = payload.value
  },
  [SET_TOKEN](state, payload) {
    state.isLoggedIn = true
    state.token = payload
  },
  [SET_CUSTOMER_NO](state, payload) {
    state.customerNo = payload
  },
  [SET_USER](state, payload) {
    state.user = payload
    state.isSystem = payload?.System === 1
  },
  [CLEAR_TOKEN](state) {
    state.isLoggedIn = false
    state.token = null
    state.user = null
  },
  [SET_PERMISSION](state, payload) {
    state.permission = payload
  },
}
