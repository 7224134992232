export default {
  loading: false,
  consumptions: {
    success: true,
    message: '操作成功',
    errorcode: 0,
    value: {
      labels: [],
      datas: [],
      WattByDat: 0,
      WattByMonth: 640,
      WattByYear: 640,
    },
  },
  consumption: {},
  totalCount: 0,
  page: 0,
  label:''
}
