import consumption from '../modules/consumption/store'
import login from '../modules/login/store'
import quality from '../modules/quality/store'
import abnormal from '../modules/abnormal/store'
import frequency from '../modules/frequency/store'
import timelimit from '../modules/timelimit/store'
import electricity from '../modules/electricity/store'
import customer from '../modules/customer/store'
import malfunction from '../modules/malfunction/store'
import signinout from '../modules/signinout/store'
import lognotify from '../modules/lognotify/store'
import operation from '../modules/operation/store'
import defSearch from '../modules/defSearch/store'
import connectionRateDate from '../modules/connectionRateDate/store'
import connectionRateDateYear from '../modules/connectionRateDateYear/store'
import connectionRate from '../modules/connectionRate/store'
import gateway from '../modules/gateway/store'
import controller from '../modules/controller/store'
import lamp from '../modules/lamp/store'
import groupSet from '../modules/groupSet/store'
import repair from '../modules/repair/store'
import staff from '../modules/staff/store'
import user from '../modules/user/store'
import dispatch from '../modules/dispatch/store'
import controllerDef from '../modules/controllerDef/store'
import controllerDefault from '../modules/controllerDefault/store'

import lostConnectionFrequency from '../modules/lostConnectionFrequency/store'
import logLostConnection from '../modules/logLostConnection/store'
import suspectPowerFailure from '../modules/suspectPowerFailure/store'
import zone from '../modules/zone/store'
import lampController from '../modules/lampController/store'
import dashboard from '../modules/dashboard/store'
import scene from '../modules/scene/store'
import gatewayLog from '../modules/gatewayLog/store'
import gatewayQue from '../modules/gatewayQue/store'
import paramQue from '../modules/paramQue/store'
import commandQue from '../modules/commandQue/store'
import plcRelay from '../modules/plcRelay/store'
import timer from '../modules/timer/store'
import logMalfunction from '../modules/logMalfunction/store'
import bridge from '../modules/bridge/store'
import bridgeSchedule from '../modules/bridgeSchedule/store'
import importFile from '../modules/importFile/store'

const storeData = {
  modules: {
    consumption,
    login,
    quality,
    abnormal,
    frequency,
    timelimit,
    electricity,
    customer,
    malfunction,
    signinout,
    lognotify,
    operation,
    defSearch,
    connectionRateDate,
    connectionRate,
    gateway,
    controller,
    lamp,
    groupSet,
    repair,
    staff,
    user,
    dispatch,
    controllerDef,
    lostConnectionFrequency,
    logLostConnection,
    suspectPowerFailure,
    zone,
    connectionRateDateYear,
    lampController,
    dashboard,
    scene,
    gatewayLog,
    gatewayQue,
    paramQue,
    commandQue,
    plcRelay,
    timer,
    logMalfunction,
    bridge,
    bridgeSchedule,
    controllerDefault,
    importFile,
  },
}
export default storeData.modules
