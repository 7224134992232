export default {
  loading: false,
  timelimit: {
    success: true,
    message: '操作成功',
    errorcode: 0,
    value: {
      labels: [],
      datas: [],
      WattByDat: 0,
      WattByMonth: 640,
      WattByYear: 640,
    },
  },
  chart: {
    labels: ['2023-10-01', '2023-10-02'],
    datasets: [
      {
        label: '維修完成燈具數',
        backgroundColor: 'white',
        data: [1, 3],
      },
      {
        label: '總維修時數',
        backgroundColor: 'blue',
        data: [48, 360],
      },
      {
        label: '平均每台維修時數',
        backgroundColor: 'red',
        data: [48, 360],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
