import { SET_PLC_RELAY_TOTAL,SET_PLC_RELAY, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchPlcRelayItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/plcrelay/item',
      { params: params },
    )

    // console.log('fetch', response?.data.value )
    commit(SET_PLC_RELAY, response?.data.value ?? [])
    commit(SET_PLC_RELAY_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchPlcRelayItemListTime({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/plcrelay/list_time',
      { params: params },
    )

    return response?.data.value || []
  },
}
