import {
  SET_COMMAND_QUE,
  SET_COMMAND_QUE_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchCommandQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/command_que_list/list',
      { params: params },
    )
    commit(SET_COMMAND_QUE, response?.data.value ?? [])
    commit(SET_COMMAND_QUE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchCommandQueName({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/command_que_list/list',
      { params: params },
    )
    return response?.data.value || []
  },
  async deleteCommandQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/command_que_list/del',
      params ,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
