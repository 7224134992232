import {
  SET_CUSTOMERS,
  SET_CUSTOMERS_TOTAL,
  SET_LOADING,
  SET_PAGE,
} from './mutations'
import axios from 'axios'

export default {
  async fetchCustomers({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/customer/list',
      { params: params },
    )
    commit(SET_CUSTOMERS, response?.data?.value ?? [])
    commit(SET_CUSTOMERS_TOTAL, response?.total ?? 0)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
}
