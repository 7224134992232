import { SET_LOADING, SET_CONSUMPTIONS, SET_LABEL } from "./mutations";
import axios from 'axios'

export default {
  async fetchConsumptions({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/consumption',
      { params: params },
    )
    commit(SET_LABEL, params.label ?? '1')
    commit(SET_CONSUMPTIONS, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
