export const SET_REPAIR = 'setRepair'
export const SET_REPAIR_TOTAL = 'setRepairTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_REPAIR](state, repair) {
    state.repair = repair
  },
  [SET_REPAIR_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
