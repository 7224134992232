import { createApp } from 'vue'
//import VueGoogleMaps from '@fawmi/vue-google-maps'
import App from './App.vue'
import router from './router'
import store from '@/store'
import i18n from './plugins/i18n'
//import uibuilderPlugin from './uibuilderPlugin.js'
//import { io } from 'socket.io-client'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 共用的常數資料
const globalConfig = {
  //web_url: 'https://app.neotroni.com/api',
  // web_url: 'http://127.0.0.1:1882',
  // web_url: 'http://60.249.17.113:1882/api',
  web_url: 'http://dev.neotroni.com:1990/api',
  // web_url: 'https://app.neotroni.com/api',
  google_key: 'AIzaSyD2L1Oo1eYYzSGL0x5YA4CECDqNL57XiUY',
  username: 'root',
  password: 'password123',
}
import Paperizer from 'paperizer'

// console.log('Set GlobalConfig')
const app = createApp(App)
app.use(store).use(router).use(Paperizer)
app.use(ElementPlus)
/*
// 設定預設語系
// 這裡使用'zh-TW'作為預設語系，你可以根據需要更改為其他語系
const defaultLocale = 'zh-TW'
// 檢查瀏覽器是否支援navigator.language屬性，並在支援時取得瀏覽器的語系
const browserLanguage = window.navigator.language
// 取得支援的語系列表
const supportedLocales = i18n.global.availableLocales
// 檢查瀏覽器語系是否在支援列表中，若是則使用瀏覽器語系，否則使用預設語系
const localeToUse = supportedLocales.includes(browserLanguage)
  ? browserLanguage
  : defaultLocale
// 設定i18n的語系
i18n.global.locale.value = localeToUse
*/
// 掛載i18n套件
app.use(i18n)
// 註冊全局屬性，使 $t 可在整個應用程式中使用
app.config.globalProperties.$t = i18n.global.t
app.use(CoreuiVue)
/*
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD2L1Oo1eYYzSGL0x5YA4CECDqNL57XiUY',
    language: 'tw',
  },
})
*/
app.provide('globalConfig', globalConfig)
app.provide('icons', icons)
/*app.component('root-component', {
  setup() {
    onMounted(() => {
      alert('test')
      // 在组件挂载到 DOM 后执行的代码
      console.log('２组件已经挂载到 DOM')
    })
    return {} // 这里需要返回一个空对象
  },
})*/

app.component('CIcon', CIcon)
//app.component('DocsExample', DocsExample)
//創建 Socket.IO 連接
//const socket = io('http://localhost:1880/uibuilder/socket')
//將 Socket.IO 實例掛載到 Vue 實例的 $socket 屬性上
//app.config.globalProperties.$socket = socket
/*
app.component('root-component', {
  data() {
    return {
      //Socket.IO 接收的資料
      msg_data: [],
    }
  },
  methods: {
    switchToRoute(rt) {
      this.$router.push(rt)
    },
  },
  mounted() {
    //監聽 'msg' 事件，接收數據
    this.$socket.on('msg', (data) => {
      console.log('msg topic:' + data.topic)
      this.msg_data = data.payload
    })
  },
})
*/
var mobile = app.mount('#app')
export default mobile
