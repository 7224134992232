export const SET_ZONE = 'setZone'
export const SET_ZONE_TOTAL = 'setZoneTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_ZONE](state, zone) {
    state.zone = zone
  },
  [SET_ZONE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
