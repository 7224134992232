import { SET_LOG_LOSTS, SET_LOG_LOSTS_TOTAL, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchLogLost({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/logLostConnection/list',
      { params: params },
    )
    commit(SET_LOG_LOSTS, response?.data.value ?? [])
    commit(SET_LOG_LOSTS_TOTAL, response?.data?.total_count ?? 0)

    commit(SET_LOADING, false)
    return response
  },
}
