import {
  SET_ANOMALY_LIST,
  SET_SUSPECT_POWER_FAILURE,
  SET_SUSPECT_POWER_FAILURE_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchSuspectPowerFailures({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/suspectPowerFailure/list',
      { params: params },
    )
    commit(SET_SUSPECT_POWER_FAILURE, response?.data.value ?? [])
    commit(SET_SUSPECT_POWER_FAILURE_TOTAL, response?.data?.total_count ?? 0)

    commit(SET_LOADING, false)
    return response
  },
  async fetchLoadAnomalyRecord({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/suspectPowerFailure/record',
      { params: params },
    )
    commit(SET_ANOMALY_LIST, response?.data.value ?? [])

    commit(SET_LOADING, false)
    return response
  },
}
