export const SET_ELECTRICITY = 'setElectricity'
export const SET_ELECTRICITY_TOTAL = 'setElectricityTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_ELECTRICITY](state, electricity) {
    state.electricity = electricity
  },
  [SET_ELECTRICITY_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
