import { SET_LOADING, SET_TIMELIMIT, SET_CHART } from './mutations'
import axios from 'axios'

export default {
  async fetchTimelimit({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/timelimie',
      { params: params },
    )

    console.log(response.data)
    const chart = {
      labels: ['2023-10-01', '2023-10-02', '2023-10-03'],
      datasets: [
        {
          label: params.white,
          backgroundColor: 'white',
          data: [1, 3],
        },
        {
          label: params.blue,
          backgroundColor: 'blue',
          data: [48, 360],
        },
        {
          label: params.red,
          backgroundColor: 'red',
          data: [48, 360],
        },
      ],
    }

    chart.labels = response.data.value?.map((item) => {
      let label = item.label
      return label
    })

    chart.datasets[0].data = response.data.value?.map((item) => {
      return item.RepairCount
    })
    chart.datasets[1].data = response.data.value?.map((item) => {
      return item.TotalHoursElapsed
    })

    chart.datasets[2].data = response.data.value?.map((item) => {
      return item.AveHoursElapse
    })

    commit(SET_CHART, chart ?? [])
    commit(SET_TIMELIMIT, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
