import {
  SET_LOADING,
  SET_REPAIR_TOTAL,
  SET_REPAIR,
  SET_PAGE,
} from './mutations'
import axios from 'axios'

export default {
  async fetchRepair({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/repair/list',
      { params: params },
    )
    commit(SET_REPAIR, response?.data.value ?? [])
    commit(SET_REPAIR_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
  async createRepair({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/repair/add',
      { ...params },
    )
    return response
  },
}
