export const SET_CONSUMPTIONS = 'setConsumptions'
export const SET_CONSUMPTIONS_TOTAL = 'setConsumptionTotal'
export const SET_CONSUMPTION = 'setConsumption'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'
export const SET_LABEL = 'setLabel'
export default {
  [SET_CONSUMPTIONS](state, consumptions) {
    state.consumptions = consumptions
  },
  [SET_CONSUMPTIONS_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_CONSUMPTION](state, consumption) {
    state.consumption = consumption
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
  [SET_LABEL](state, label) {
    state.label = label
  },
}
