import { SET_LOADING, SET_CONNECTION_RATE, SET_CHART } from './mutations'
import axios from 'axios'

export default {
  async fetchConnectionRateDate({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/connectionRateDate',
      { params: params },
    )
    const chart = {
      labels: ['2023-11-01', '2023-11-02', '2023-11-03', '2023-11-04'],
      datasets: [
        {
          label: params.label,
          backgroundColor: 'rgb(0,139,0,1)',
          data: ['100', '80', '90', '70'],
        },
      ],
    }

    chart.labels = response.data.value?.map((item) => {
      let label = item.LogDate
      return label
    })

    chart.datasets[0].data = response.data.value?.map((item) => {
      return item.DailyConnectionRate.toFixed(2)
    })

    // console.log('fetchConnectionRate22', chart.labels)
    commit(SET_CHART, chart ?? [])
    // console.log('response=>', response.data)
    commit(SET_CONNECTION_RATE, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
