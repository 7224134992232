import {
  SET_CONTROLLER_DEF,
  SET_CONTROLLER_DEF_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/def_controller/list',
      { params: params },
    )
    commit(SET_CONTROLLER_DEF, response?.data.value ?? [])
    commit(SET_CONTROLLER_DEF_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
