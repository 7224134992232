export const SET_USER = 'setUser'
export const SET_USER_TOTAL = 'setUserTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_USER](state, users) {
    state.users = users
  },
  [SET_USER_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
