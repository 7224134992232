import { createI18n } from 'vue-i18n'
// 載入語言
import tw from '@/lang/zh-TW.json'
import en from '@/lang/en-US.json'

const i18n = createI18n({
  //設定預設語言
  locale: localStorage.getItem("locale") ?? 'tw',
  legacy: false,
  //若選擇的語言沒有語系檔或缺字則退回的語言
  fallbackLocale: 'tw',
  messages: {
    tw,
    en,
  },
  // 其他常用的配置
})
export default i18n
