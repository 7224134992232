<template>
  <div v-if="loading" class="loading-overlay">
    <!-- Loading 動畫代碼 -->
    <div class="spinner"></div>
  </div>
  <router-view :key="$route.fullPath" />
  <!-- <app-menu></app-menu> -->
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
import axios from 'axios'

export default {
  name: 'App',
  setup() {},
  data() {
    return {
      loading: false, // 初始化 loading 狀態為 false
    }
  },
  components: {},
  methods: {},
  created() {
    // 在這裡設置全局的 Loading 狀態
    // 例如，您可以在 Axios 請求開始和結束時調用相應的方法
    // 這裡僅作示例，實際應根據您的需求配置
    axios.interceptors.request.use(
      (config) => {
        this.loading = true // 開始 Loading
        //console.log('開始 Loading')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    axios.interceptors.response.use(
      (response) => {
        this.loading = false // 結束 Loading
        //console.log('結束 Loading')
        return response
      },
      (error) => {
        this.loading = false // 結束 Loading
        //console.log('結束 Loading')
        return Promise.reject(error)
      },
    )
  },
}
/*
import AppMenu from '@/components/AppMenu'
import { store } from './store'
import { provideI18n } from './plugins/i18n'
import tw from './lang/zh-TW.json'
import en from './lang/en-US.json'

export default {
  components: {
    AppMenu,
  },
  setup() {
    let locale = 'tw'
    if (localStorage.getItem('setLang')) {
      locale = localStorage.getItem('setLang')
      store.commit('setLang', locale)
    } else {
      store.commit('setLang', locale)
    }
    provideI18n({
      locale: locale,
      messages: {
        tw: tw,
        en: en,
      },
    })
  },
}
*/
</script>
<style>
/* 全局 CSS 文件，例如在 styles.css 或 App.vue 的 style 部分 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* 半透明黑色背景，可以自行調整顏色和透明度 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999; /* 設定 z-index 以確保在其他元素上方 */
}

.spinner {
  /* Loading 動畫的樣式，可以自行設計或引入相應的庫 */
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #007bff; /* Loading 條的顏色 */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
