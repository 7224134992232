import { SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async previewFile({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/import_preview/'+params.type,
      { ...params },
    )

    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async importFile({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/import/'+params.type,
      { ...params },
    )
    return response?.data.value || []
  },

}
