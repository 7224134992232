export const logoNegative = [
  '556 134',
  `
  <title>Neotroni LOGO</title>
<g transform="translate(0.000000,204.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M981 1886 c-8 -9 -12 -33 -9 -61 l3 -45 -32 6 c-18 3 -44 3 -58 0
-22 -6 -25 -12 -25 -54 -1 -45 -4 -50 -76 -122 -90 -89 -130 -163 -113 -207 9
-25 11 -26 20 -10 8 13 7 22 -1 32 -9 11 -9 23 -1 47 12 37 86 126 132 159 27
19 32 19 47 7 35 -31 45 -31 23 0 -19 28 -20 33 -7 38 9 3 28 -1 42 -11 25
-16 25 -16 15 3 -6 11 -16 22 -23 24 -7 3 6 17 27 31 l40 27 49 -56 c56 -63
103 -96 165 -115 36 -11 42 -16 38 -36 -2 -12 4 -46 14 -75 13 -37 18 -83 19
-150 0 -54 4 -98 9 -98 39 0 52 75 26 150 -8 23 -19 68 -25 99 -5 31 -12 65
-15 77 -5 17 0 23 27 34 51 20 68 40 68 77 0 34 -31 106 -50 118 -38 23 35 35
79 12 13 -6 39 -39 58 -72 33 -57 34 -64 30 -145 -3 -72 -10 -100 -45 -185
-23 -54 -40 -100 -38 -102 5 -5 131 189 156 241 22 46 22 50 6 75 -9 14 -24
42 -32 61 -9 19 -24 53 -35 74 -10 22 -18 41 -16 42 2 2 29 -2 61 -8 51 -11
59 -16 86 -58 16 -25 36 -62 45 -82 17 -43 20 -155 4 -211 -6 -22 -9 -41 -6
-43 2 -2 20 11 40 28 l35 33 5 117 4 118 -33 25 c-19 14 -34 30 -34 34 0 4 32
0 72 -10 40 -10 74 -17 75 -15 8 8 -88 45 -145 57 -41 9 -73 23 -94 41 -18 16
-46 39 -63 51 l-30 22 28 -34 c15 -18 27 -36 27 -40 0 -15 -118 15 -156 40
-21 14 -50 32 -64 39 -24 12 -23 11 5 -20 l30 -32 -70 5 c-41 3 -96 16 -132
31 -82 34 -164 44 -182 22z m63 -35 c3 -4 32 -18 65 -31 63 -23 85 -46 108
-110 23 -63 19 -73 -27 -67 -44 6 -67 24 -90 71 -8 17 -24 35 -35 40 -26 11
-52 46 -61 80 -5 22 -3 26 14 26 12 0 23 -4 26 -9z"/>
<path d="M935 1558 c-10 -23 -30 -58 -44 -77 -13 -19 -20 -31 -14 -28 21 13
24 -12 3 -33 -26 -26 -38 -25 -52 5 -6 14 -14 25 -17 25 -8 -1 -64 -80 -74
-104 -4 -10 -16 -31 -27 -46 -11 -15 -24 -40 -30 -56 l-10 -29 22 30 c47 63
108 121 108 103 0 -25 6 -23 55 18 45 37 71 84 54 101 -6 6 3 19 24 36 39 30
50 58 33 82 -11 15 -15 11 -31 -27z"/>
<path d="M2000 1560 c35 -124 -21 -310 -124 -413 -101 -101 -295 -201 -524
-268 -103 -31 -111 -35 -224 -126 -65 -51 -118 -97 -118 -102 0 -16 152 -23
240 -11 156 20 280 71 409 167 143 107 264 252 332 397 28 60 58 176 59 223 0
43 -21 118 -40 143 l-20 25 10 -35z"/>
<path d="M741 1510 c-1 -8 -4 -25 -7 -37 -5 -17 -2 -23 10 -23 17 0 20 23 6
55 -6 13 -9 15 -9 5z"/>
<path d="M964 1445 c11 -17 26 -36 33 -43 7 -7 13 -26 13 -41 0 -14 6 -51 14
-81 9 -35 11 -62 5 -77 -8 -23 -8 -23 16 -7 28 18 32 45 10 64 -10 9 -15 32
-15 75 0 63 0 63 -47 101 -47 38 -47 38 -29 9z"/>
<path d="M1470 1232 c-72 -152 -111 -200 -207 -257 -43 -25 -80 -45 -84 -45
-3 0 -10 20 -16 44 -7 33 -7 48 2 58 18 19 35 76 35 117 l-1 36 -18 -23 c-11
-12 -22 -41 -26 -65 -10 -60 -18 -70 -71 -87 -26 -8 -54 -25 -62 -37 -14 -23
-14 -23 24 -23 22 0 54 4 72 9 47 14 50 -5 17 -90 -15 -39 -25 -73 -22 -76 3
-3 47 29 97 72 51 42 113 91 138 107 26 17 61 40 78 51 17 11 37 29 43 39 12
20 64 264 59 278 -2 5 -28 -44 -58 -108z"/>
<path d="M600 1268 c-97 -110 -101 -132 -33 -168 26 -14 52 -35 58 -46 18 -36
38 -56 72 -70 26 -12 33 -21 42 -64 6 -28 11 -63 11 -78 0 -23 7 -30 33 -40
44 -16 47 -15 47 8 0 11 6 20 13 20 14 0 97 74 97 86 0 4 -10 -2 -23 -14 -25
-23 -46 -26 -121 -16 -40 6 -46 10 -46 31 0 13 -5 33 -10 44 -9 15 -7 19 7 20
20 1 -32 20 -57 21 -21 1 -50 28 -50 46 0 9 5 30 12 49 15 42 6 51 -29 23
l-28 -21 -17 21 c-27 34 -22 68 20 124 68 91 69 100 2 24z"/>
<path d="M869 1094 c22 -29 20 -44 -7 -44 -10 0 -33 -14 -52 -30 l-35 -31 38
4 c36 3 37 2 37 -30 0 -47 13 -45 89 14 3 2 4 22 2 45 -2 41 -27 72 -74 90
-17 7 -17 6 2 -18z m11 -64 c-8 -5 -22 -10 -30 -10 -13 0 -13 1 0 10 8 5 22
10 30 10 13 0 13 -1 0 -10z"/>
<path d="M2193 565 c-19 -21 -16 -45 6 -45 18 0 51 34 51 52 0 14 -44 8 -57
-7z"/>
<path d="M400 395 c0 -158 1 -165 20 -165 19 0 20 7 20 115 0 63 4 115 8 115
5 0 42 -52 83 -115 58 -91 78 -115 94 -113 19 3 20 12 23 166 l2 162 -24 0
-25 0 -3 -115 -3 -116 -75 115 c-59 91 -80 116 -97 116 -23 0 -23 -1 -23 -165z"/>
<path d="M1330 524 c0 -27 -5 -37 -20 -41 -11 -3 -20 -13 -20 -23 0 -10 9 -20
20 -23 18 -5 20 -14 20 -106 0 -94 1 -101 20 -101 19 0 20 7 20 105 0 98 1
105 20 105 13 0 20 7 20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40
-20 40 -16 0 -20 -7 -20 -36z"/>
<path d="M794 479 c-47 -13 -76 -51 -82 -107 -8 -91 53 -151 142 -139 46 6
106 61 106 97 0 30 -38 26 -50 -5 -14 -36 -67 -59 -104 -45 l-29 11 82 55 c89
61 98 81 54 112 -35 24 -79 32 -119 21z m83 -55 c3 -10 -103 -84 -121 -84 -16
0 -3 50 19 74 20 22 33 27 62 24 20 -2 38 -8 40 -14z"/>
<path d="M1085 474 c-22 -7 -41 -14 -42 -14 -1 0 -11 -16 -23 -36 -18 -28 -21
-44 -16 -76 9 -55 21 -75 59 -98 65 -40 154 -13 183 55 19 46 18 74 -6 112
-36 59 -92 80 -155 57z m100 -59 c46 -45 24 -125 -39 -141 -34 -8 -82 20 -95
57 -28 81 74 145 134 84z"/>
<path d="M1685 482 c-35 -8 -77 -44 -91 -79 -52 -124 117 -231 210 -134 51 53
44 150 -13 190 -29 20 -75 30 -106 23z m70 -48 c19 -8 45 -55 45 -84 0 -30
-53 -80 -83 -80 -14 0 -39 12 -56 26 -76 64 -1 174 94 138z"/>
<path d="M1962 479 c-54 -16 -73 -58 -70 -159 3 -77 5 -85 23 -85 18 0 20 8
23 85 4 94 17 120 62 120 16 0 35 -10 48 -25 18 -21 22 -37 22 -100 0 -60 3
-75 15 -75 12 0 15 15 15 79 0 91 -10 124 -42 144 -34 22 -59 26 -96 16z"/>
<path d="M1494 462 c-36 -28 -44 -57 -44 -146 0 -79 2 -86 20 -86 18 0 20 7
20 83 0 74 3 86 23 105 13 12 29 22 35 22 7 0 12 9 12 20 0 25 -36 27 -66 2z"/>
<path d="M2180 360 c0 -100 3 -120 15 -120 13 0 15 20 15 120 0 100 -2 120
-15 120 -12 0 -15 -20 -15 -120z"/>
<path d="M1050 136 c0 -14 4 -27 8 -30 5 -3 6 8 4 25 -5 35 -12 38 -12 5z"/>
<path d="M700 124 c0 -15 3 -23 8 -18 4 5 10 11 13 14 4 3 1 11 -7 18 -12 11
-14 8 -14 -14z"/>
<path d="M872 125 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1552 130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2022 130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M561 124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1881 124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M408 103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M730 102 c0 -6 -8 -13 -17 -15 -16 -4 -16 -5 -1 -6 18 -1 34 16 24
26 -3 4 -6 1 -6 -5z"/>
<path d="M2188 103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
`,
]
