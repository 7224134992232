import {
  SET_PARAM_QUE,
  SET_PARAM_QUE_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchParamQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/param_que_list/list',
      { params: params },
    )
    commit(SET_PARAM_QUE, response?.data.value ?? [])
    commit(SET_PARAM_QUE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchParamQueName({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/param_que_list/list',
      { params: params },
    )
    return response?.data.value || []
  },
  async deleteQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/param_que_list/del',
      params ,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
