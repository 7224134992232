export const SET_LOGS = 'setLogs'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_LOGS](state, logs) {
    state.logs = logs
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
