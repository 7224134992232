export const SET_MALFUNCTION = 'setMalfunction'
export const SET_MALFUNCTION_TOTAL = 'setMalfunctionTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_MALFUNCTION](state, malfunction) {
    state.malfunction = malfunction
  },
  [SET_MALFUNCTION_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
