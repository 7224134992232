export const SET_LAMPS = 'setLamps'
export const SET_LAMP = 'setLamp'
export const SET_LAMP_TOTAL = 'setLampTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_LAMPS](state, lamp) {
    state.lamp = lamp
  },
  [SET_LAMP](state, lampItem) {
    state.lampItem = lampItem
  },
  [SET_LAMP_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
