import {
  SET_USER,
  SET_PERMISSION,
  SET_TOKEN,
  CLEAR_TOKEN,
  SET_UPDATE_SIDEBAR_VISIBLE,
  SET_CUSTOMER_NO,
} from './mutations'
import axios from 'axios'

export default {
  async doLogin({ commit }, params) {
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/login',
      params,
    )
    // console.log('token', response.data.value.Token)
    // console.log('permission', response.data.value.User.Permission)
    if (response.data.success) {
      commit(SET_USER, response.data.value.User)
      commit(SET_PERMISSION, response.data.value.User.Permission)
      commit(SET_TOKEN, response.data.value.Token)
      commit(SET_CUSTOMER_NO, response.data.value?.User?.CustomerNo)
      localStorage.setItem('token', response.data.value.Token)
      localStorage.setItem('CustomerNo', response.data.value.User.CustomerNo)
      localStorage.setItem('Permission', response.data.value.User.Permission)
    }
    return response
  },
  async verifyToken({ commit }) {
    const response = await axios.post(process.env.VUE_APP_BASEURL + '/verify', {
      token: localStorage.getItem('token'),
    })

    commit(SET_USER, response.data?.value?.User)
    commit(SET_PERMISSION, response.data?.value?.User?.Permission)
    commit(SET_TOKEN, response.data?.value?.Token)
    commit(SET_TOKEN, response.data.value?.User?.CustomerNo)

    // console.log('permission', response.data.value.User.Permission)
    // console.log(
    //   'response.data.value?.User?.CustomerNo',
    //   response.data.value?.User?.CustomerNo,
    // )

    localStorage.setItem('token', response.data?.value?.Token)
    localStorage.setItem('CustomerNo', response.data.value?.User?.CustomerNo)
    localStorage.setItem('Permission', response.data.value?.User?.Permission)
    return response.data
  },
  loginUser({ commit }, user) {
    commit(SET_USER, user)
    commit(SET_PERMISSION, user.Permission)
    commit(SET_CUSTOMER_NO, user.CustomerNo)
    //
    localStorage.setItem('CustomerNo', user.CustomerNo)
    localStorage.setItem('Permission', user.Permission)
  },
  loginToken({ commit }, token) {
    //console.log('Login setToken:' + JSON.stringify(token))
    commit(SET_TOKEN, token)
    // 儲存 token
    // localStorage.setItem('token', token)
  },
  async logoutUser({ commit }) {
    await axios.post(process.env.VUE_APP_BASEURL + '/logout', {
      token: localStorage.getItem('token'),
    })

    commit(CLEAR_TOKEN)
    // 移除 token
    // localStorage.removeItem('CustomerNo')
    // localStorage.removeItem('Permission')
    localStorage.removeItem('token')
    // 跳轉 login page
  },
  updateSideBarVisiable({ commit }, param) {
    commit(SET_UPDATE_SIDEBAR_VISIBLE, param)
  },
}
