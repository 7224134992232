export const SET_QUALITY = 'setQuality'
export const SET_SEARCH_TYPE = 'setSearchType'
export const SET_CHART = 'setChart'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_QUALITY](state, quality) {
    state.quality = quality
  },
  [SET_SEARCH_TYPE](state, searchType) {
    state.searchType = searchType
  },
  [SET_CHART](state, chart) {
    state.chart = chart
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
