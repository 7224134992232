import { SET_LOADING, SET_CONNECTION_RATE, SET_CHART } from './mutations'
import axios from 'axios'
import moment from 'moment/moment'

export default {
  async fetchConnectionRate({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/connectionRate',
      { params: params },
    )
    let label = params.label
    if (params.searchStr) {
      label = 'RSSI強度'
    }

    const chart = {
      labels: ['2023-11-01', '2023-11-02', '2023-11-03', '2023-11-04'],
      datasets: [
        {
          label: label,
          backgroundColor: 'rgb(0,139,0,1)',
          data: ['100', '80', '90', '70'],
        },
      ],
    }
    if (params.searchStr) {
      chart.labels = response.data.value?.map((item) => {
        let label = moment(item.LogDateTime).format('HH:mm')
        return label
      })

      chart.datasets[0].data = response.data.value?.map((item) => {
        return item.RSSI
      })
    } else {
      chart.labels = response.data.value?.map((item) => {
        let label = moment(item.LogDateTime).format('HH:mm')

        return label
      })

      chart.datasets[0].data = response.data.value?.map((item) => {
        return item.Onlines
      })
    }

    // console.log('fetchConnectionRate22', chart.labels)
    commit(SET_CHART, chart ?? [])
    commit(SET_CONNECTION_RATE, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
