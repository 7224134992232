import { SET_LOADING, SET_CONNECTION_RATE, SET_CHART } from './mutations'
import axios from 'axios'

export default {
  async fetchConnectionRateDateYear({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/connectionRateDateYear',
      { params: params },
    )
    // console.log(response.data)
    const chart = {
      labels: [''],
      datasets: [
        {
          label: params.label,
          backgroundColor: 'rgb(0,139,0,1)',
          data: ['100', '80', '90', '70'],
        },
      ],
    }

    chart.labels = response.data.value?.map((item) => {
      let label = item.LogYear + '-' + item.LogMonth
      return label
    })

    chart.datasets[0].data = response.data.value?.map((item) => {
      return item.MonthlyConnectionRate
    })

    // console.log('fetchConnectionRate22', chart.labels)
    commit(SET_CHART, chart ?? [])
    // console.log('response=>', response.data)
    commit(SET_CONNECTION_RATE, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
