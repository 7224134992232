import { SET_ZONE_TOTAL, SET_ZONE, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchZone({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/zone/list',
      { params: params },
    )
    // console.log('response:', response?.data.value)

    commit(SET_ZONE, response?.data.value ?? [])
    commit(SET_ZONE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createZone({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/zone/add',
      params,
    )
    commit(SET_ZONE, response?.data.value ?? [])
    commit(SET_ZONE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
  },
  async updateZone({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/zone/edit',
      params,
    )
    console.log('response', response.data)
    commit(SET_LOADING, false)
  },
}
