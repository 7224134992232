export const SET_TIMELIMIT = 'setTimelimit'
export const SET_CHART = 'setChart'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_TIMELIMIT](state, timelimit) {
    state.timelimit = timelimit
  },
  [SET_CHART](state, chart) {
    state.chart = chart
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
