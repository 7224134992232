export const SET_GATEWAY_LOG = 'setGatewayLog'
export const SET_GATEWAY_LOG_TOTAL = 'setGatewayLogTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_GATEWAY_LOG](state, gatewayLog) {
    state.gatewayLog = gatewayLog
  },
  [SET_GATEWAY_LOG_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
