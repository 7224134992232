export default {
  loading: false,
  connectionRateDate: {},
  chart: {
    labels: [''],
    datasets: [
      {
        label: '',
        backgroundColor: 'rgb(0,139,0,1)',
        data: ['100', '80', '90', '70'],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
