export const SET_BRIDGE = 'setBridge'
export const SET_BRIDGE_TOTAL = 'setBridgeTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_BRIDGE](state, bridges) {
    state.bridges = bridges
  },
  [SET_BRIDGE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
