export default {
  loading: false,
  frequency: {},
  chart: {
    labels: ['功率異常', '過電壓', '無回應(超過24小時)', '低電壓'],
    datasets: [
      {
        backgroundColor: ['#27408B', '#006400', '#FFFF00', '#FF0000'],
        data: [40, 20, 80, 10],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
