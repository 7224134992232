export const SET_PLC_RELAY = 'setPlcRelay'
export const SET_PLC_RELAY_TOTAL = 'setPlcRelayTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_PLC_RELAY](state, plcRelay) {
    state.plcRelay = plcRelay
  },
  [SET_PLC_RELAY_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
