<template>
  <div class="fixed-div">
    <CContainer>
      <CRow class="align-items-center justify-content-center">
        <CCol>
          <div id="CircleArea"></div>
        </CCol>
        <CCol>
          <CWidgetStatsA color="primary">
            <template #title> {{$t('header.TotalControllers')}}</template>
            <template #value><h3 id="TotalControllers">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
        <CCol>
          <CWidgetStatsA color="success">
            <template #title>  {{$t('header.OnLines')}}</template>
            <template #value><h3 id="OnLines">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
        <CCol>
          <CWidgetStatsA color="dark">
            <template #title>  {{$t('header.OffLines')}}</template>
            <template #value><h3 id="OffLines">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
        <CCol>
          <CWidgetStatsA color="warning">
            <template #title>  {{$t('header.Abnormals')}}</template>
            <template #value><h3 id="Abnormals">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
        <CCol>
          <CWidgetStatsA color="danger">
            <template #title> {{$t('header.Errors')}}</template>
            <template #value><h3 id="Errors">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
        <CCol>
          <CWidgetStatsA class="border-primary text-dark">
            <template #title>  {{$t('header.WaitForRepair')}}</template>
            <template #value><h3 id="WaitForRepair">0</h3></template>
            <template #chart>&nbsp;</template>
          </CWidgetStatsA>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer style="padding: 5px">
      <CRow>
        <CCol md="5" class="me-auto" style="margin-left: 10px">
          <CRow>
            <div style="width: 50px">
              <img
                class="d-block w-auto"
                src="sunrise.png"
                style="width: 32px; height: 32px"
                alt="slide 1"
              />
            </div>
            <CCol md="1"> {{ sunrise }} </CCol>
            <div style="width: 50px">
              <img
                class="d-block w-auto"
                style="width: 32px; height: 32px"
                src="sunset.png"
                alt="slide 1"
              />
            </div>
            <CCol md="1"> {{ sunset }} </CCol>
            <div style="width: 50px">
              <img
                class="d-block w-auto"
                style="width: 32px; height: 32px"
                src="light.svg"
                alt="slide 1"
              />
            </div>
            <CCol md="1"> {{ onValue }} </CCol>
            <div style="width: 50px">
              <img
                class="d-block w-auto"
                style="width: 32px; height: 32px"
                src="nolight.svg"
                alt="slide 1"
              />
            </div>
            <CCol md="1"> {{ offValue }} </CCol>
          </CRow>
        </CCol>
        <CCol xs="auto" style="text-align: right; padding: 5px">
           {{$t('header.LastUpdateTime')}}：<label id="LastUpdateTime"></label>
        </CCol>
      </CRow>
    </CContainer>
  </div>

  <CHeaderDivider />
  <!--<CContainer fluid>
    <AppBreadcrumb />
  </CContainer>-->
</template>

<script>
//import AppBreadcrumb from './AppBreadcrumb'
//import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { inject, onMounted, ref } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

var post_url = ''

export default {
  name: 'AppHeader',
  components: {},
  setup() {
    const sunrise = ref('')
    const sunset = ref('')
    const onValue = ref(0)
    const offValue = ref(0)
    const linkCustomerNo = ref('')
    const searchStr = ref('')
    const route = useRoute()
    const getLocation = () => {
      const lat =
        localStorage.getItem('centerLat') !== null
          ? parseInt(localStorage.getItem('centerLat'))
          : 0
      const long =
        localStorage.getItem('centerLng') !== null
          ? parseInt(localStorage.getItem('centerLng'))
          : 0
      sunrise.value = lat.toFixed(5)
      sunset.value = long.toFixed(5)
      const times = suntimes(lat, long)
      // console.log('time', times)

      let sunrise_hour = Math.floor(times[0])
      let sunrise_minute = parseInt(
        (times[0] - Math.floor(times[0])) * 60,
      ).toFixed(0)

      if (sunrise_hour < 10) {
        sunrise_hour = '0' + sunrise_hour
      }

      if (sunrise_minute < 10) {
        sunrise_minute = '0' + sunrise_minute
      }

      let sunset_hour = Math.floor(times[1])
      let sunset_minute = parseInt(
        (times[1] - Math.floor(times[1])) * 60,
      ).toFixed(0)

      if (sunset_minute < 10) {
        sunset_minute = '0' + sunset_minute
      }

      // console.log('sunrise_hour', sunrise_hour)
      // console.log('sunrise_minute', sunrise_minute)
      //
      // console.log('sunset_hour', sunset_hour)
      // console.log('sunset_minute', sunset_minute)

      sunrise.value = sunrise_hour + ':' + sunrise_minute
      sunset.value = sunset_hour + ':' + sunset_minute
    }

    const globalConfig = inject('globalConfig')
    post_url = globalConfig.web_url + '/main'

    onMounted(async () => {
      getLocation()
      linkCustomerNo.value = localStorage.getItem('CustomerNo')

      fetchData(route.params.stype)
    })

    const fetchData = async (stype) => {
      axios
        .get(post_url + '/list', {
          params: {
            CustomerNo: linkCustomerNo.value,
            stype,
            searchStr: searchStr.value,
          },
        })
        .then((response) => {
          //console.log('fetchData:', JSON.stringify(response.data.value))
          if (response.data.value.lamplist.length) {
            onValue.value = response.data.value.onoff.onnum
            offValue.value = response.data.value.onoff.offnum
          }
        })
        .catch((error) => {
          // 處理錯誤
          console.error('錯誤:', error)
          alert('讀取資料列表失敗，請稍後再試或聯絡管理員')
        })
    }

    const suntimes = (lat, lng, tz) => {
      var d = new Date()
      var radians = Math.PI / 180.0
      var degrees = 180.0 / Math.PI

      var a = Math.floor((14 - (d.getMonth() + 1.0)) / 12)
      var y = d.getFullYear() + 4800 - a
      var m = d.getMonth() + 1 + 12 * a - 3
      var j_day =
        d.getDate() +
        Math.floor((153 * m + 2) / 5) +
        365 * y +
        Math.floor(y / 4) -
        Math.floor(y / 100) +
        Math.floor(y / 400) -
        32045
      var n_star = j_day - 2451545.0009 - lng / 360.0
      var n = Math.floor(n_star + 0.5)
      var solar_noon = 2451545.0009 - lng / 360.0 + n
      var M = 356.047 + 0.9856002585 * n
      var C =
        1.9148 * Math.sin(M * radians) +
        0.02 * Math.sin(2 * M * radians) +
        0.0003 * Math.sin(3 * M * radians)
      var L = (M + 102.9372 + C + 180) % 360
      var j_transit =
        solar_noon +
        0.0053 * Math.sin(M * radians) -
        0.0069 * Math.sin(2 * L * radians)
      var D =
        Math.asin(Math.sin(L * radians) * Math.sin(23.45 * radians)) * degrees
      var cos_omega =
        (Math.sin(-0.83 * radians) -
          Math.sin(lat * radians) * Math.sin(D * radians)) /
        (Math.cos(lat * radians) * Math.cos(D * radians))

      // sun never rises
      if (cos_omega > 1) return [null, -1]

      // sun never sets
      if (cos_omega < -1) return [-1, null]

      // get Julian dates of sunrise/sunset
      var omega = Math.acos(cos_omega) * degrees
      var j_set = j_transit + omega / 360.0
      var j_rise = j_transit - omega / 360.0

      /*
       * get sunrise and sunset times in UTC
       * Check section "Finding Julian date given Julian day number and time of
       *  day" on wikipedia for where the extra "+ 12" comes from.
       */
      var utc_time_set = 24 * (j_set - j_day) + 12
      var utc_time_rise = 24 * (j_rise - j_day) + 12
      var tz_offset = tz === undefined ? (-1 * d.getTimezoneOffset()) / 60 : tz
      var local_rise = (utc_time_rise + tz_offset) % 24
      var local_set = (utc_time_set + tz_offset) % 24
      return [local_rise, local_set]
    }

    return {
      logo,
      getLocation,
      sunrise,
      sunset,
      suntimes,
      onValue,
      offValue,
      searchStr,
    }
  },
}
</script>
<style>
.fixed-div {
  position: sticky;
  top: 0; /* 固定在視窗頂部 */
  left: 0; /* 固定在視窗左側 */
  width: 100%; /* 擴展至視窗寬度 */
  background-color: #fff; /* 設定背景色 */
  z-index: 999; /* 設定z-index以確保在其他元素上方 */
}
</style>
