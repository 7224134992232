export const SET_CONTROLLER_DEFAULT = 'setControllerDefault'
export const SET_CONTROLLER_DEFAULT_TOTAL = 'setControllerDefaultTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_CONTROLLER_DEFAULT](state, controllers) {
    state.controllers = controllers
  },
  [SET_CONTROLLER_DEFAULT_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
