export const SET_SCENES = 'setScenes'
export const SET_SCENES_TOTAL = 'setScenesTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_SCENES](state, scenes) {
    state.scenes = scenes
  },
  [SET_SCENES_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
