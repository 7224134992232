export const SET_OPERATION = 'setOperation'
export const SET_OPERATION_TOTAL = 'setOperationTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_OPERATION](state, operation) {
    state.operation = operation
  },
  [SET_OPERATION_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
