export const SET_BRIDGE_SCHEDULE = 'setBridgeSchedule'
export const SET_BRIDGE_SCHEDULE_TOTAL = 'setBridgeScheduleTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_BRIDGE_SCHEDULE](state, bridge_schedules) {
    state.bridge_schedules = bridge_schedules
  },
  [SET_BRIDGE_SCHEDULE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
