export const SET_DASHBOARD = 'setDashboard'
export const SET_DASHBOARD_TOTAL = 'setDashboardTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard
  },
  [SET_DASHBOARD_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
