export const SET_TIMER = 'setTimer'
export const SET_TIMER_TOTAL = 'setTimerTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_TIMER](state, timer) {
    state.timer = timer
  },
  [SET_TIMER_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
