export default {
  loading: false,
  connectionRate: {},
  chart: {
    labels: [
      '2023-10-01 09:10',
      '2023-10-01 09:20',
      '2023-10-01 09:30',
      '2023-10-01 09:40',
      '2023-10-01 09:50',
      '2023-10-01 10:00',
      '2023-10-01 10:10',
      '2023-10-01 10:20',
      '2023-10-01 10:30',
      '2023-10-01 10:40',
    ],
    datasets: [
      {
        label: '連線設備數量',
        backgroundColor: 'rgb(0,139,0,1)',
        data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
