import {
  SET_GATEWAY_QUE,
  SET_CONTROLLER_QUE,
  SET_GATEWAY_QUE_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchGatewayQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/param_que/gateway',
      { params: params },
    )
    commit(SET_GATEWAY_QUE, response?.data.value ?? [])
    commit(SET_GATEWAY_QUE_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchControllerQue({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/param_que/controller',
      { params: params },
    )
    commit(SET_CONTROLLER_QUE, response?.data.value ?? [])
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
