import { SET_GROUP_SET_TOTAL, SET_GROUP_SET, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchGroupSet({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/group/list',
      { params: params },
    )
    commit(SET_GROUP_SET, response?.data.value ?? [])
    commit(SET_GROUP_SET_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createGroupSet({ commit }) {
    commit(SET_LOADING, true)
    commit(SET_LOADING, false)
  },
}
