export const SET_DISPATCH = 'setDispatch'
export const SET_DISPATCH_TOTAL = 'setDispatchTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_DISPATCH](state, dispatch) {
    state.dispatch = dispatch
  },
  [SET_DISPATCH_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
