import {
  SET_CONTROLLER_DEFAULT,
  SET_CONTROLLER_DEFAULT_TOTAL,
  SET_LOADING,
} from './mutations'
import axios from 'axios'

export default {
  async fetchControllerDefault({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/controller_default/list',
      { params: params },
    )
    commit(SET_CONTROLLER_DEFAULT, response?.data.value ?? [])
    commit(SET_CONTROLLER_DEFAULT_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchControllerDefaultNotLinkGateway({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/controller_default/not_link_gateway',
      { params: params },
    )

    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async deleteControllerDefault({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/controller_default/delitem',
      { ...params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateControllerDefault({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/controller_default/update_controller',
      { ...params },
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
