import {
  SET_CHART,
  SET_LOADING,
  SET_QUALITY,
  SET_SEARCH_TYPE,
} from './mutations'
import axios from 'axios'
import store from '@/store'

export default {
  async fetchQuality({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/quality',
      { params: params },
    )
    let label = params.label //'最大輸入電壓'
    if (parseInt(store.state.quality.searchType) === 2) {
     // label = '最大輸出電壓(V)'
    } else if (parseInt(store.state.quality.searchType) === 3) {
      //label = '最大功率(W)'
    } else if (parseInt(store.state.quality.searchType) === 4) {
      //label = '最大功率因數(PF)'
    } else if (parseInt(store.state.quality.searchType) === 5) {
     // label = '耗電量(Watt)'
    } else {
      //label = '最大輸入電壓'
    }
    const chart = {
      labels: ['2023-10-01', '2023-10-02', '2023-10-03'],
      datasets: [
        {
          label: label,
          backgroundColor: 'rgb(0,139,0,1)',
          data: [30, 39, 10],
        },
      ],
    }

    chart.labels = response.data.value?.map((item) => {
      let label = item.LogDate
      return label
    })
    chart.datasets[0].data = response.data.value?.map((item) => {
      let rtn = item.VOutMax
      if (parseInt(store.state.quality.searchType) === 1) {
        rtn = item?.VInMax
      } else if (parseInt(store.state.quality.searchType) === 2) {
        rtn = item?.VOutMax
      } else if (parseInt(store.state.quality.searchType) === 3) {
        rtn = item?.WattageMax
      } else if (parseInt(store.state.quality.searchType) === 4) {
        rtn = item?.PFMax
      } else if (parseInt(store.state.quality.searchType) === 5) {
        rtn = item?.RunWatt
      } else {
        rtn = item.VOutMax
      }
      return rtn
    })

    // console.log('fetchConnectionRate22', chart.labels)
    commit(SET_CHART, chart ?? [])

    commit(SET_QUALITY, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
  async updateSearchType({ commit }, params = 1) {
    commit(SET_LOADING, true)

    commit(SET_SEARCH_TYPE, params)
    commit(SET_LOADING, false)
  },
}
