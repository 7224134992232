import { SET_CONTROLLERS, SET_CONTROLLER_TOTAL, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/lamp_controller/list',
      { params: params },
    )
    commit(SET_CONTROLLERS, response?.data.value ?? [])
    commit(SET_CONTROLLER_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateLampController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    await axios.post(
      process.env.VUE_APP_BASEURL + '/lamp_controller/edit',
      params,
    )
    commit(SET_LOADING, false)
  },
  async updateCleanLampController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    await axios.post(
      process.env.VUE_APP_BASEURL + '/lamp_controller/cleanZoneNo',
      params,
    )
    commit(SET_LOADING, false)
  },
}
