export const SET_STAFF = 'setStaff'
export const SET_STAFF_TOTAL = 'setStaffTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_STAFF](state, staff) {
    state.staff = staff
  },
  [SET_STAFF_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
