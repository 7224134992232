export default {
  loading: false,
  abnormal: {
    success: true,
    message: '操作成功',
    errorcode: 0,
    value: {
      labels: [],
      datas: [],
      WattByDat: 0,
      WattByMonth: 640,
      WattByYear: 640,
    },
  },
  chart: {
    labels: [],
    datasets: [
      {
        label: '次數',
        backgroundColor: '#104E8B',
        data: [],
      },
    ],
  },
  totalCount: 0,
  page: 0,
}
