export const SET_COMMAND_QUE = 'setCommandQue'
export const SET_COMMAND_QUE_TOTAL = 'setCommandQueTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_COMMAND_QUE](state, commandQue) {
    state.commandQue = commandQue
  },
  [SET_COMMAND_QUE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
