import { SET_SCENES_TOTAL,SET_SCENES, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchScenes({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/scene/list',
      { params: params },
    )
    commit(SET_SCENES, response?.data.value ?? [])
    commit(SET_SCENES_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchScene({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/scene/item/',
      { params: params },
    )
    return response?.data.value || []
  },
  async createScene({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/scene/add',
      params,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateSceneItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/scene/additems',
      params,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async updateScene({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/scene/edit',
      params,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async deleteScene({ commit }, params) {
    commit(SET_LOADING, true);
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/scene/delete',
      params,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async playScene({ commit }, params) {
    commit(SET_LOADING, true);
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/scene/play',
      params,
    )
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
}
