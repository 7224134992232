import { SET_TIMER_TOTAL,SET_TIMER, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchTimerItem({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/timer/item',
      { params: params },
    )

    // console.log('fetch', response?.data.value )
    commit(SET_TIMER, response?.data.value ?? [])
    commit(SET_TIMER_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchTimerItemListTime({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/timer/list_time',
      { params: params },
    )

    return response?.data.value || []
  },
}
