import {
  SET_MALFUNCTION,
  SET_MALFUNCTION_TOTAL,
  SET_LOADING,
  SET_PAGE,
} from './mutations'
import axios from 'axios'

export default {
  async fetchMalfunction({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/malfunction/list',
      { params: params },
    )
    commit(SET_MALFUNCTION, response?.data.value ?? [])
    commit(SET_MALFUNCTION_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
  async createMalfunction({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/malfunction/add',
      { params: params },
    )
    commit(SET_MALFUNCTION, response?.data ?? [])
    commit(SET_MALFUNCTION_TOTAL, response?.total ?? 0)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
}
