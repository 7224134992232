export const SET_CUSTOMERS = 'setCustomers'
export const SET_CUSTOMERS_TOTAL = 'setCustomersTotal'
export const SET_LOADING = 'setLoading'
export const SET_PAGE = 'setPage'

export default {
  [SET_CUSTOMERS](state, customers) {
    state.customers = customers
  },
  [SET_CUSTOMERS_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_PAGE](state, page) {
    state.page = page
  },
}
