import {
  SET_DASHBOARD_TOTAL,
  SET_DASHBOARD,
  SET_LOADING,
  SET_PAGE,
} from './mutations'
import axios from 'axios'

export default {
  async fetchDashboard({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/dashboard/list',
      { params: params },
    )
    commit(SET_DASHBOARD, response?.data.value ?? [])
    commit(SET_DASHBOARD_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_PAGE, response?.page ?? 1)
    commit(SET_LOADING, false)
    return response
  },
  async updateDashboard({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/dashboard_record/update',
      { ...params },
    )

    return response
  },
  async updateDashboardBridgeRelay({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/dashobar_bridge/relay',
      { ...params },
    )

    return response
  },
  async updateDashboardBridgeRs232({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/dashobar_bridge/rs232',
      { ...params },
    )

    return response
  },
}
