export const SET_SUSPECT_POWER_FAILURE = 'setSuspectPowerFailure'
export const SET_SUSPECT_POWER_FAILURE_TOTAL = 'setSuspectPowerFailureTotal'
export const SET_ANOMALY_LIST = 'setAnomalyList'

export const SET_LOADING = 'setLoading'

export default {
  [SET_SUSPECT_POWER_FAILURE](state, suspectPowerFailures) {
    state.suspectPowerFailures = suspectPowerFailures
  },
  [SET_ANOMALY_LIST](state, recordList) {
    state.recordList = recordList
  },
  [SET_SUSPECT_POWER_FAILURE_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
}
