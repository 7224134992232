import { SET_ABNORMAL, SET_LOADING, SET_CHART } from './mutations'
import axios from 'axios'

export default {
  async fetchAbnormal({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/chart/abnormal',
      { params: params },
    )

    console.log('fetchAbnormal freq', response.data.value)

    const chart = {
      labels: ['功率異常', '過電壓', '無回應(超過24小時)', '低電壓'],
      datasets: [
        {
          label: params.label,
          backgroundColor: '#104E8B',
          data: [],
        },
      ],
    }

    chart.labels = response.data?.value?.map((item) => {
      return item.MFDate
    })

    chart.datasets[0].data = response.data?.value?.map((item) => {
      return item.MFNum
    })
    commit(SET_CHART, chart ?? [])

    commit(SET_ABNORMAL, response?.data ?? [])
    commit(SET_LOADING, false)
    return response
  },
}
