export const SET_LOST_CONNECT_FREQ = 'setLostConnectFreq'
export const SET_LOST_CONNECT_FREQ_TOTAL = 'setLostConnectFreqTotal'
export const SET_LOADING = 'setLoading'

export default {
  [SET_LOST_CONNECT_FREQ](state, lostConnections) {
    state.lostConnections = lostConnections
  },
  [SET_LOST_CONNECT_FREQ_TOTAL](state, totalCount) {
    state.totalCount = totalCount
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
}
